import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PageHeader from './components/PageHeader.vue'
import PageHeader1 from './components/PageHeader1.vue'
import PageFooter from './components/PageFooter.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import ba from 'vue-ba'
import {Table,TableColumn,Input,Button,Col,Row,Popover,	Pagination} from 'element-ui';
import animation from 'animate.css'
import 'element-ui/lib/theme-chalk/index.css';

// require styles
import 'swiper/dist/css/swiper.css'
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(VueAxios, axios)
Vue.prototype.VueAxios = axios;
Vue.use(router)
Vue.component("pageheader",PageHeader);
Vue.component("pagefooter",PageFooter);
Vue.component("pageheader1",PageHeader1);
Vue.use(ba, "e95ceb1c590802e90ac88db80a59c6d3");
Vue.use(ba, { siteId: "e95ceb1c590802e90ac88db80a59c6d3" });
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Input);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(animation);

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
