<template>
	<div id="app">
		<div id="nav">

			<router-view />
		<pagefooter></pagefooter>
	</div>
    
  </div>
</template>

<script>
import DevicePixelRatio from './lib/devicePixelRatio.js';
export default {
  name: "app",
  created() {
	new DevicePixelRatio().init();
	this.linkTo=this.$route.path.substr(1)
	console.log(this.linkTo)
  }
};
</script>

<style>
#app {
	/*padding-top: 90px;*/
}
a{
	text-decoration:none;
	color: inherit;
}
.image-swiper-pagination{
	margin-top: 52px;
	height: 4px;
	display: flex;
	justify-content: center;
	background-color: white;
}
.image-swiper-pagination-bullet{
	display: inline-block;
	width:27px;
	height:4px;
	margin-right: 7px;
	background:rgba(0,0,0,0.1);
	cursor: pointer;
}
.active-image-swiper-pagination-bullet{
	width:28px;
	height:4px;
	background:rgba(0,0,0,0.4);
}
	body{
		padding: 0;
		margin: 0;
	}
</style>
