<template>
	<div id="body">
		<pageheader :linkTo='linkTo'></pageheader>

		<div class="bg-1">
			<div style="flex-direction: column;margin: 0 160px 100px 180px;">
				<img style="width: 303px;margin-bottom: 30px" src="../assets/home/10s快速制作.png" />
				<div style="display: flex;align-items: flex-start">
					<div style="display: flex;flex-direction: column;margin-right: 20px">
						<div @click="iosDownload" style="margin-bottom:10px;cursor: pointer ">
							<img style="width: 154px;height: 42px" src="../assets/home/ios下载.png">
						</div>
						<div @click="andriodDownload" style="cursor: pointer">
							<img style="width: 154px;height: 42px" src="../assets/home/安卓下载.png">
						</div>
					</div>
					<img style="width: 90px" src="../assets/home/一寸二维码.png">
				</div>
			</div>
			<img style="width: 250px;margin-bottom: 50px" src="../assets/home/首页1.png" />
			<img style="width: 250px;margin-top: 100px" src="../assets/home/首页2.png" />
		</div>
		<div class="bg-2">
			<img v-show="image1" class="animate__animated animate__fadeInLeft" style="width: 382px;top: -50px;left: 200px" src="../assets/home/3.png" />
			<img v-show="image2" class="animate__animated animate__fadeInUp" style="width: 327px;top: 50px;left: 700px" src="../assets/home/600+.png" />
			<img v-show="image3" class="animate__animated animate__fadeInUp" style="width: 208px;top: 550px;left: 300px" src="../assets/home/自然美颜.png" />
			<img v-show="image4" class="animate__animated animate__fadeInRight" style="width: 289px;top: 300px;left: 700px" src="../assets/home/4.png">
			<img v-show="image5" class="animate__animated animate__fadeInLeft" style="width: 412px;top: 700px;left: 250px" src="../assets/home/5.png">
			<img v-show="image6" class="animate__animated animate__fadeInUp" style="width: 278px;top: 950px;left: 700px" src="../assets/home/精美正装.png">
			<img v-show="image7" class="animate__animated animate__fadeInUp" style="width: 313px;top: 1450px;left: 250px" src="../assets/home/形象照.png">
			<img v-show="image8" class="animate__animated animate__fadeInLeft" style="width: 224px;top: 1300px;left: 700px" src="../assets/home/6.png">
			<img v-show="image9" class="animate__animated animate__fadeInRight" style="width: 224px;top:1600px;left: 300px" src="../assets/home/7.png">
			<img v-show="image10" class="animate__animated animate__fadeInUp" style="width: 313px;top:1800px;left: 700px" src="../assets/home/多张证件照.png">
		</div>
		<div style="height: 2146px">

		</div>

	</div>
</template>
<script>
export default {
	name: 'home',
	data(){
		return {
			image1:false,image2:false,image3:false,image4:false,image5:false,image6:false,image7:false,
			image8:false,image9:false,image10:false,
		}
	},
	methods:{
		iosDownload(){
			window.location.href="https://apps.apple.com/cn/app/id1254349355"
		},
		andriodDownload(){
			window.location.href="https://sj.qq.com/myapp/detail.htm?apkName=com.leqi.YicunIDPhoto"
		},
		getScroll() {
			this.scroll = document.documentElement.scrollTop || document.body.scrollTop;

			if (this.scroll>500){
				this.image1=true
				this.image2=true
			}
			if (this.scroll>900){
				this.image3=true
				this.image4=true
			}
			if (this.scroll>1200){
				this.image5=true
				this.image6=true
			}
			if (this.scroll>1750){
				this.image7=true
				this.image8=true
			}
			if (this.scroll>2200){
				this.image9=true
				this.image10=true
			}

		}
	},
	mounted(){
		window.addEventListener('scroll', this.getScroll)
	},
	created() {
		this.linkTo=this.$route.path.substr(1)

	},
	computed: {

	},
}
</script>

<style scoped="scoped">

	.bg-1{
		background-image: url(../assets/home/第一屏-背景.png);
		width:100%;
		background-size: cover;
		height: 800px;
		/*margin-top:-90px;*/
		display: flex;
		align-items: center;

	}
	.bg-2{
		background-image: url(../assets/home/背景2.png);
		width:100%;
		background-size: cover;
		height: 2146px;
		/*margin-top:-90px;*/
		position: absolute;
		top: 800px;


	}
	.bg-2>img{
		position: absolute;

	}
	.foot{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}




</style>
