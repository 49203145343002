<template>
	<div>
		<div class="foot">
			<div style="width: 100%;display: flex;">
				<div style="width: 50%">
					手机：18012485542
				</div>
				<div style="width: 50%">
					联系我们：0510-81819939
				</div>
			</div>
			<div style="width: 100%;display: flex;">
				<div style="width: 50%">
					邮箱：best-idphoto@venpoo.com
				</div>
				<div style="width: 50%">
					咨询时间：周一至周五8:00-11：30,13:00-17:30
				</div>
			</div>

			<div style="width: 100%;display: flex;">
				<div style="width: 50%">
					总部：无锡市新吴区菱湖大道111号无锡软件园天鹅座D座1601室
				</div>
				<div style="width: 50%" @click="skipruhua" class="linkruhua">
					友情链接：如画-见字如画
				</div>
			</div>

		</div>
		<div style="width: 100%;display: flex;background-color: #DADEE4;justify-content: center">
			Copyright © 2019 无锡乐骐科技股份有限公司 | <a target="_blank"
				href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021402001005">苏公网安备 32021402001005号</a>
			<a target="_blank" href="http://www.beian.miit.gov.cn/state/outPortal/loginPortal.action">苏ICP备14019013号-8</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageFooter',
	methods: {
		skipruhua: () => {
			window.open('https://ruhua.ai/', '_blank')
		}

	},
}
</script>

<style scoped="scoped">
.foot {
	width: 72%;
	height: 60px;
	background-color: #DADEE4;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	padding: 20px 14%;
}

.linkruhua {
	cursor: pointer;
	user-select: none;
}

div {
	font-size: 10px;
	color: #334761;
}
</style>
