<template>
    <div>
        <pageheader1 :linkTo='linkTo'></pageheader1>
        <div style="height: 60px" />
        <div style="margin-left: 10%;margin-top: 100px;font-size: 35px">
            {{name}}
        </div>
        <div style="margin-left: 10%;margin-top: 50px;margin-bottom: 50px">
            <span>{{time}}</span>
            <span style="margin-left: 20px" v-for="item in tag" :key="item">
                #{{item}}
            </span>
        </div>
        <div id="detail">


        </div>
    </div>
</template>


<script>


    export default {

        name: "ArticleDetail",
        data() {
            return {
                content:'',
                title:'',
                tag:'',
                time:'',
            }
        },
        mounted() {

            this.getDetail()
        },
        created() {

            this.linkTo=this.$route.path.substr(1)
            console.log(this.linkTo)
        },
        methods:{

            getDetail(){
                let id=this.$route.query.artId
                console.log(this.$route.query.artId)
                let that=this
                this.VueAxios.get(`https://api.id-photo-verify.com/news/content/details?id=${id}`,{       // 还可以直接把参数拼接在url后边
                }).then(function(res){

                    that.content=res.data.result.content
                    that.name=res.data.result.title
                    that.tag=res.data.result.tag

                    console.log(that.tag)
                    that.time=res.data.result.create_time
                    document.getElementById('detail').innerHTML=that.content

                })
            },


        },
    }
</script>

<style scoped>

    #detail{
        width: 70%;
        margin-left: 15%;
    }
    span{
        font-size: 11px;
        color: #333333;
    }


</style>