<template>
    <div>
        <pageheader1 :linkTo='linkTo'></pageheader1>
        <div style="height: 60px" />
        <img class="banner" src="../assets/banner_article.png" />

        <Row style="margin: 20px 0" :gutter="10">
            <Col :span="2" :offset="4">
                <span style="font-size: 18px;font-weight: 600">热门文章</span>
            </Col>
            <Col :span="4" :offset="8">
                <Input

                        size="small"
                        placeholder="请输入关键词开始搜索"
                        prefix-icon="el-icon-search"
                        v-model="search"
                >
                </Input>
            </Col>
            <Col :span="2">
                <Button @click="handleSearch" size="small" type="primary">搜索</Button>
            </Col>
            <Col :span="3">
                <span style="margin-right: 5px">公务员</span>
                <span style="margin-right: 5px">会计考试</span>
                <span style="margin-right: 5px">旅游</span>
            </Col>
        </Row>
        <div v-for="item in articleList" style="display: flex;justify-content: space-between;width: 60%;margin: 20px auto;flex-wrap: wrap" :key="item.article_id">
            <img style="width: 8px;height: 100px" src="../assets/article_line.png" />
            <div style="width: 90%;">

                <div>
                    <div style="width: 70%;float: left">{{item.title}}</div>
                    <div style="font-size: 10px;float: right;margin-left: 10px" v-for="childItem in item.tag" :key="childItem">
                        #{{childItem}}
                    </div>
                </div>
                <div class="article-content">
                    {{item.part_content}}

                </div>
                <button @click="goDetail(item.article_id)" class="btn">
                    阅读全文>
                </button>
            </div>
            <div class="split" />
        </div>
        <div style="display: flex;justify-content: center;margin: 50px">
            <button @click="handlePage('pre')" class="page-btn-per">上一页</button>
            <button @click="choosePage(item)" class="page-btn" :class="{'page-btn-on':item===pageNum,'page-btn-off':item!==pageNum}"  v-for="item in pageData" :key="item">
                {{item}}
            </button>
            <button @click="handlePage('next')" class="page-btn-per">下一页</button>
        </div>

    </div>
</template>


<script>
    import {Input,Col,Row,Button} from 'element-ui';
    import DevicePixelRatio from "../lib/devicePixelRatio";

    export default {
        components:{
            Input,Col,Row,Button
        },
        name: "Article",
        data() {
            return {
                search:'', // 查询内容
                articleList:[],
                pageNum:1,
                pageData:[],
            }
        },
        created() {

            this.linkTo=this.$route.path.substr(1)
            console.log(this.linkTo)
        },
        mounted() {

            this.getList()
        },
        methods:{

            getList(){
                let that=this
                this.VueAxios.get(`https://api.id-photo-verify.com/news/articles?page=${this.pageNum}`,{       // 还可以直接把参数拼接在url后边
                }).then(function(res){
                    that.articleList = Array.isArray(res.data.result)?res.data.result:[];
                    let length=Math.ceil(res.data.total_number/4)
                    let list=[]
                    for (let i=1;i<=length;i++){
                        list.push(i)
                    }

                    that.pageData=list
                })
            },
            handlePage(flag){
                if (flag==='next'&&this.pageNum<this.pageData.length){
                    this.pageNum++
                }
                if (flag==='pre'&&this.pageNum>1){
                    this.pageNum--
                }
                this.getList()
            },
            choosePage(pageNum){
                this.pageNum=pageNum
                this.getList()
            },
            handleSearch(){
                let that=this
                this.VueAxios.get(`https://api.id-photo-verify.com/news/articles?page=${this.pageNum}&key=${this.search}`,{       // 还可以直接把参数拼接在url后边
                }).then((res)=>{
                    that.articleList = Array.isArray(res.data.result)?res.data.result:[];

                })
            },
            goDetail(id){
                console.log('artId',id)
                this.$router.push({
                    path: '/articleDetail',
                    query: {
                        artId:id
                    }
                })
            }

        },
    }
</script>

<style scoped>
    .banner{

        width: 100%;
        height: 180px;

    }
    .article-content{
        display: inline-block;
        margin: 40px 0 20px 0;
        height: 27px;
        width: 100%;
        font-size: 10px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .btn{
        border-radius: 3px;
        width: 60px;
        height: 25px;
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        margin-right: 10px;
        background-color: #FFFFFF;
        border: 1px solid #777777;
        cursor: pointer;
    }

    span{
        font-size: 11px;
        color: #333333;
    }
    .split{
        border: 0.5px dashed #f1f1f1;
        width: 100%;
        margin-top:20px;
        /*height: 1px;*/
    }
    .page-btn{
        border-radius: 3px;
        width: 25px;
        height: 25px;
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        margin-right: 10px;
        background-color: #FFFFFF;
    }
    .page-btn-on{
        border:1px solid #3072F6;
        color: #3072F6;

    }
    .page-btn-off{
        border:1px solid #aaaaaa;
        color: #777777;
    }
    .page-btn-per{
        border-radius: 3px;
        width: 50px;
        height: 25px;
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        margin-right: 10px;
        border:1px solid #aaaaaa;
        color: #777777;
        background-color: #FFFFFF;
    }


</style>