import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Examination from '../views/Examination.vue'
import Article from "../views/Article.vue"
import ArticleDetail from "../views/ArticleDetail.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/article',
    name: 'article',
    component: Article
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: ArticleDetail
  },
  {
    path: '/examination',
    name: 'examination',
    component: Examination
  },
]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes
})

export default router
