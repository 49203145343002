<template>
	<div>

		<div class="top">
			<img src="../assets/home/一寸-logo.png" class='logo' alt="logo" title='最美证件照制作' />
			<div class='tab-container'>
				<span class='tab tab-off' :class="(chosenTab==''||chosenTab=='home')?'chosen':''" data-tab='home' @click="chooseTab">首页</span>

<!--				<span class='tab tab-off' :class="chosenTab=='Article'?'chosen':''" data-tab='Article' @click="chooseTab">热门文章</span>-->
				<span class='tab tab-off' :class="chosenTab=='Examination'?'chosen':''" data-tab='Examination' @click="chooseTab">考试咨询</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
	linkTo: String
  },
  data(){
		return {
			chosenTab:this.linkTo,
			show:false,
			isHomePage:false,
		}
  },
  methods:{
		chooseTab:function(e){
			this.chosenTab=e.target.dataset.tab;
			this.$router.push({path:'/'+e.target.dataset.tab});
		},


  },
	mounted(){




	},
}
</script>

<style scoped="scoped">
	.chosen{
		color: #FF7700;
		/*border-bottom: 3px #FF7700 solid;*/
	}
	.top{
		position: fixed;
		top:0;
		z-index: 9999;
		background:rgba(255,255,255,1);
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height:60px;
		padding: 0 19%;
		box-sizing: border-box;
	}


	.logo{
		width: 140px;
		flex-shrink: 0;
		flex-grow: 0;
		margin-right: 20px;
	}
	.tab-container{
		display: inline-block;
		height: 100%;
		flex-shrink: 0;
		flex-grow: 0;
		width: 700px;
		line-height: 60px;
		cursor: pointer;
	}
	.tab{
		display: inline-block;
		width: 70px;
		font-size: 14px;
		margin: 0 25px;
		box-sizing: border-box;
		text-align: center;
	}
	.tab-off {
	}

	/* @media (min-width: 1923px) {
		.top{
			width:1920px;
			height:90px;
		}
		#logo{
			width: 230px;
		}
		footer{
			padding-bottom: 32px;
			padding-top: 52px;
		}
		.footer-upper-1>img{
			width: 110px;
			margin-bottom: 9px;
		}
	}
	@media (min-width: 1200px) and (max-width: 1922px){
		.top{
			width:1200px;
			height:85px;
		}
		#logo{
			width: 151px;
		}
		footer{
			padding-bottom: 31px;
			padding-top: 31px;
		}
		.footer-upper-1>img{
			width: 110px;
			margin-bottom: 5px;
		}
	}
	@media (min-width: 992px) and (max-width: 1199px){
		.top{
			width:992px;
			height:67px;
		}
		#logo{
			width: 123px;
		}
		footer{
			padding-bottom: 35px;
			padding-top: 30px;
		}
		.footer-upper-1>img{
			width: 90px;
			margin-bottom: 10px;
		}
	}
	@media (min-width: 768px) and (max-width: 991px) {
		.top{
			width:768px;
			height:50px;
		}
		#logo{
			width: 108px;
		}
		footer{
			padding-bottom: 31px;
			padding-top: 24px;
		}
		.footer-upper-1>img{
			width: 69px;
			margin-bottom: 10px;
		}
	} */
</style>
