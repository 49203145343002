<template>
    <div>
        <pageheader1 :linkTo='linkTo'></pageheader1>
        <div style="height: 60px" />
        <div class="banner">
            2020年最新官方报考资讯

        </div>

        <div style="display: flex;flex-direction: column;align-items: center" v-for="item in categories" :key="item.first_category">

            <div style="width:75%;display: flex;align-items: center;justify-content: space-between;margin: 10px 0px">
                <div style="width: 10%">
                    <img class="icon" :src="item.icon"/>
                    <span style="font-size: 15px;font-weight:bold;margin-left: 10px">{{item.first_category}}</span>
                </div>
                <div style="width: 90%">
                    <span @click="searchName(secondItem)" style="margin-right: 20px;cursor: pointer;" v-for="secondItem in item.second_category" :key="secondItem">
                        {{secondItem}}
                    </span>
                </div>

            </div>
        </div>

        <Row style="margin: 20px 0" :gutter="0">
            <Col :span="2" :offset="3">
                <span style="font-size: 18px;font-weight: 600">报考资讯</span>
            </Col>
            <Col :span="4" :offset="11">
                <Input

                        size="small"
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        v-model="search"
                >
                </Input>
            </Col>
            <Col :span="1">
                <Button @click="handleSearch" size="small" type="primary">搜索</Button>
            </Col>
        </Row>
        <Table

                :data="examineList"
                stripe

                header-cell-style="text-align:center;color:#2C4B84;background-color:#F5F6FA"
                cell-style="text-align:center;font-size:11px;color:#333333"
                style="width: 75%;margin-left: 12.5%">

            <TableColumn
                    prop="test_name"
                    label="考试名称"
                    width="200"
            >
                <template slot-scope="scope">
                    <div style="display: flex;align-items: center">
                        <span>{{examineList[scope.$index].test_name}}</span>
                        <img v-show="examineList[scope.$index].is_hot" style="width: 30px;height: 15px;margin-left: 5px" src="../assets/examination/tag_hot.png" />
                    </div>
                </template>
            </TableColumn>
            <TableColumn
                    prop="schedule"
                    label="报名时间"
                    width="180">
            </TableColumn>
            <TableColumn
                    prop="create_time"
                    label="考试时间">
            </TableColumn>
            <TableColumn
                    label="官方来源"
                    prop="official_source"
            >
                <template slot-scope="scope">
                    <Popover placement="top-start" width="200" trigger="hover" :content="examineList[scope.$index].official_source">
                        <a :href="examineList[scope.$index].official_source" style="color: #3072F6" v-show="examineList[scope.$index].official_source.indexOf('http')>-1" slot="reference" class="p-wrap">{{examineList[scope.$index].official_source}}</a>
                        <a v-show="examineList[scope.$index].official_source.indexOf('http')===-1" slot="reference" class="p-wrap">{{examineList[scope.$index].official_source}}</a>
                    </Popover>

                </template>


            </TableColumn>
            <TableColumn
                    label="报名入口"
                    prop="application_entrance"
            >
                <template slot-scope="scope">
                    <Popover placement="top-start" width="200" trigger="hover" :content="examineList[scope.$index].application_entrance">
                        <a :href="examineList[scope.$index].application_entrance" style="color: #3072F6" v-show="examineList[scope.$index].application_entrance.indexOf('http')>-1" slot="reference" class="p-wrap">{{examineList[scope.$index].application_entrance}}</a>
                        <a v-show="examineList[scope.$index].application_entrance.indexOf('http')===-1" slot="reference" class="p-wrap">{{examineList[scope.$index].application_entrance}}</a>
                    </Popover>

                </template>


            </TableColumn>
            <TableColumn
                    label="报考指南"
                    prop="register_guide"
            >
                <template slot-scope="scope">
                    <Popover placement="top-start" width="300" trigger="hover" :content="examineList[scope.$index].register_guide">
                            <p slot="reference" class="p-wrap">{{examineList[scope.$index].register_guide}}</p>
                    </Popover>

                </template>


            </TableColumn>
        </Table>

        <div style="display: flex;justify-content: center;margin: 50px">
            <button @click="handlePage('pre')" class="page-btn-per">上一页</button>
            <button @click="choosePage(item)" class="page-btn" :class="{'page-btn-on':item===pageNum,'page-btn-off':item!==pageNum}"  v-for="item in pageData" :key="item">
                {{item}}
            </button>
            <button @click="handlePage('next')" class="page-btn-per">下一页</button>
        </div>
    </div>
</template>


<script>
    import {Table,TableColumn,Input,Button,Col,Row,Popover} from 'element-ui';
    export default {
        components:{
            Table,TableColumn,Input,Button,Col,Row,Popover
        },
        name: "Examination",
        data() {
            return {
                search:'', // 查询内容
                categories:[],
                examineList:[],
                pageNum:1,
                pageData:[],
            }
        },

        mounted() {
            this.getCategories()
            this.getList()

        },
        created() {

            this.linkTo=this.$route.path.substr(1)

        },
        methods:{
            getCategories(){
                let that=this
                this.VueAxios.get('https://api.id-photo-verify.com/news/categories',{       // 还可以直接把参数拼接在url后边
                }).then(function(res){
                    that.categories = res.data.result;

                }).catch(function (error) {

                });
            },
            getList(){
                let that=this
                this.VueAxios.get(`https://api.id-photo-verify.com/news/informations?page=${this.pageNum}`,{       // 还可以直接把参数拼接在url后边
                }).then(function(res){

                    that.examineList = Array.isArray(res.data.result)?res.data.result:[];
                    let length=Math.ceil(res.data.result.length/10)
                    let list=[]
                    for (let i=1;i<=length;i++){
                        list.push(i)
                    }

                    that.pageData=list
                })
            },
            handlePage(flag){
                if (flag==='next'&&this.pageNum<this.pageData.length){
                    this.pageNum++
                }
                if (flag==='pre'&&this.pageNum>1){
                    this.pageNum--
                }
                this.getList()
            },
            choosePage(pageNum){
                this.pageNum=pageNum
                this.getList()
            },
            handleSearch(){
                let that=this
                this.VueAxios.get(`https://api.id-photo-verify.com/news/informations?page=${this.pageNum}&key=${this.search}`,{       // 还可以直接把参数拼接在url后边
                }).then((res)=>{
                    that.examineList = Array.isArray(res.data.result)?res.data.result:[];
                })
            },
            searchName(name){
                let that=this
                this.VueAxios.get(`https://api.id-photo-verify.com/news/informations?page=${this.pageNum}&key=${name}`,{       // 还可以直接把参数拼接在url后边
                }).then((res)=>{
                    that.examineList = Array.isArray(res.data.result)?res.data.result:[];
                })
                window.scrollTo({
                    top: 400,
                    behavior: 'smooth'
                });
            },


        },
    }
</script>

<style scoped>
    .banner{
        background-image:url('../assets/examination/banner_examination.png');
        width: 100%;
        height: 130px;
        text-align: center;
        line-height: 130px;

        font-size: 35px;
        color: #FFFFFF;
    }
    .p-wrap{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }
    a-color{
        color: blue !important;
    }
    .page-btn{
        border-radius: 3px;
        width: 25px;
        height: 25px;
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        margin-right: 10px;
        background-color: #FFFFFF;
    }
    .page-btn-on{
        border:1px solid #3072F6;
        color: #3072F6;

    }
    .page-btn-off{
        border:1px solid #aaaaaa;
        color: #777777;
    }
    .page-btn-per{
        border-radius: 3px;
        width: 50px;
        height: 25px;
        text-align: center;
        font-size: 10px;
        line-height: 20px;
        margin-right: 10px;
        border:1px solid #aaaaaa;
        color: #777777;
        background-color: #FFFFFF;
    }
    span{
        font-size: 11px;
        color: #333333;
    }
    .icon{
        width: 15px;
    }
    .type-contain{

    }

</style>